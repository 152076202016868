import axios from "axios";
import {DataProps} from "src/types/DataProps";
import jwt_decode from 'jwt-decode'
import {TError} from "../types/TError";
import {parseFrom, TUserData} from "../types/TUserData";
import {NotificationType, ShowToast} from "../components/Toast/Toast";
import {enqueueSnackbar} from "notistack";

export const baseUrl = "https://api.pofft.com/";
export const baseUrlV2 = "https://apiv2.pofft.com";
//export const baseUrlV2 = "https://localhost:44300";
export var userToken = ''
export var publicToken = ''
const loginRedirectUrl = "/Login/Success";
const dashboardRedirectUrl = "/Login/Success";

export var tokenExpireDate: Date = new Date();

export function setUserToken(t: string) {
  userToken = t;
}

export function getUserToken() {
  return userToken;
}

export function setPublicToken(t: string) {
  publicToken = t;
}

export const basicHeaders = {
  auth: {
    username: "PofftWebApiUser",
    password: "dtfzhj3ehkP24c6",
  },
};

export const userInstance = axios.create({
  baseURL: baseUrlV2,
})


export async function getV2(url: string, isPublicApi?: boolean): Promise<any> {
  return userInstance.get(baseUrlV2 + url, {headers: {Authorization: `Bearer ${isPublicApi ? publicToken : userToken}`}}).then((data) => {
    if (data && data.data && data.data.result != undefined && data.data.result == false) {
      return new TError(0, data.data.message);
    }
    return data;
  }).catch((ex) => {
    console.log(ex);
    return new TError(0, ex.message);
  });
}

export async function postV2(url: string, data: any, isPublicApi?: boolean): Promise<any> {
  return axios.post(baseUrlV2 + url, data, {headers: {Authorization: `Bearer ${isPublicApi ? publicToken : userToken}`}}).then((data) => {
    if (data && data.data && data.data.result != undefined && data.data.result == false) {
      return new TError(0, data.data.message);
    }
    return data;
  }).catch((ex) => {
    console.log(ex);
    return new TError(0, ex.message);
  });
}

export function filter(data: DataProps[], name: string): DataProps | undefined {
  if (data == undefined) {
    return undefined;
  }
  const found = data.filter((item: any) => item.name == name);
  if (found == undefined || found.length == 0) {
    return undefined;
  }
  return found[0];
}

export async function signUpRequest(
  email: string,
  firstname: string,
  lastname: string,
  pass: string,
  smsPermission: boolean,
  callPermission: boolean,
  emailPermission: boolean,
  companyName?: string
): Promise<true | TError> {
  const request =
    companyName != undefined && companyName.length > 0
      ? async () => {
        return axios.post(
          baseUrlV2 + "/company/create",
          {
            name: companyName,
            contactPersonName: firstname,
            password: pass,
            contactPersonSurname: lastname,
            contactPersonEmail: email,
            smsPermission: smsPermission,
            callPermission: callPermission,
            emailPermission: emailPermission,
          },
          {headers: {Authorization: `Bearer ${publicToken}`}}
        );
      }
      : async () => {
        return axios.post(
          baseUrlV2 + "/professional/create",
          {
            name: firstname,
            surname: lastname,
            email: email,
            password: pass,
            smsPermission: smsPermission,
            callPermission: callPermission,
            emailPermission: emailPermission,
          },
          {headers: {Authorization: `Bearer ${publicToken}`}}
        );
      };

  return request().then((data) => {
    if (data.data) {
      const Data = data.data;
      if (Data.result && Data.result == true) {
        return true;
      }
      debugger
      return new TError(0, Data.message);
    }
    return new TError(0, '');
  }).catch((ex) => {
    return new TError(0, '')
  });
}

export async function signInRequest(
  email: string,
  pass: string
): Promise<TError | TUserData> {
  return axios
    .post(
      baseUrlV2 + "/token/new",
      {
        memberType: 0,
        loginType: 0,
        email: email,
        password: pass,
      }
    )
    .then((data) => {
      if (data.data) {
        const Data = data.data;
        if (Data.message) {
          console.log("Error: " + Data.message);
          return new TError(0, Data.message)
        }
        if (Data.result && Data.result.result == false && Data.result.message) {
          console.log("Error: " + Data.result.message);
          return new TError(0, Data.result.message)
        }
        const decodedToken = jwt_decode(Data.token)
        let tUserData = parseFrom(Data, Data.token, decodedToken);
        return tUserData
      }
      return new TError(0, 'Bir hata oluştu');
    })
    .catch((ex) => {
      debugger
      console.log(ex.message);
      return new TError(0, ex.message);
    });
}

export async function forgetPassRequest(email: string): Promise<boolean> {
  return axios
    .get(baseUrl + "Login/forgetpassword?email=" + email, basicHeaders)
    .then((data) => {
      if (data.data) {
        const Data = data.data;
        if (Data.result && Data.result == true) {
          return true;
        }
        if (Data.message) {
          console.log("Error: " + Data.message);
        }
      }
      return false;
    })
    .catch((ex) => {
      console.log(ex);
      return false;
    });
}

export async function resetPassRequest(
  pass: string,
  login: string,
  keyID: string,
  key: string,
  memberType: string
): Promise<boolean> {
  // const url=baseUrl+memberType==0?"Company":"Professional
  const url = baseUrlV2 + "Login/resetpassword";

  return axios
    .post(
      url,
      {
        password: pass,
        passwordAgain: pass,
        login: login,
        keyID: keyID,
        key: key,
        memberType: parseInt(memberType),
      },
      basicHeaders
    )
    .then((data) => {
      if (data.data) {
        const Data = data.data;
        if (Data.result && Data.result == true) {
          return true;
        }
        if (Data.message) {
          console.log("Error: " + Data.message);
        }
      }
      return false;
    })
    .catch((ex) => {
      console.log(ex);
      return false;
    });
}

export async function activateRequest(
  url: string
): Promise<string | TError> {

  const resp = await axios.get(
    baseUrlV2 + url,
    {headers: {Authorization: `Bearer ${publicToken}`}}
  ).then((data) => {
    if (data.data) {
      const Data = data.data;
      if (Data.result && Data.result == true) {
        return Data.remark;
      }
      if (Data.message) {
        console.log("Error: " + Data.message);
      }
    }
    return false;
  })
    .catch((ex) => {
      console.log(ex);
      return new TError(1, 'Bir hata oluştu');
    });

  if (resp == false) {
    return new TError(0, 'Bir hata oluştu')
  }
  return resp
}

export function oauth2SignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create element to open OAuth 2.0 endpoint in new window.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id:
      "365896118529-l1ngj6i2t26tlgn1qitijoqov818klli.apps.googleusercontent.com",
    redirect_uri: "http://react.pofft.com",
    scope: "profile",
    state: "try_sample_request",
    include_granted_scopes: "true",
    response_type: "token",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    //@ts-ignore
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

export async function loginWgoogleLink(callback: () => void) {
  const redirectTo = window.location.origin + loginRedirectUrl;
  localStorage.setItem("login", "google");
  let headers = {Authorization: `Bearer ${publicToken}`};
  return await axios
    .get(baseUrlV2 + "/Login/logingooglegetlink", {
      headers: headers,
      params: {redirectUrl: redirectTo},
    })
    .then((data) => {
      window.location.href = (data.data /*.split(" ")[0]*/);
    });
}

export async function loginWgoogleLinkSuccess(
  code: string,
) {
  const redirectTo = window.location.origin + dashboardRedirectUrl;

  return axios
    .post(
      baseUrlV2 + "/token/new",
      {
        LoginType: "Google",
        GoogleParameter: {
          code: code,
          redirectUrl: redirectTo
        },
      }
    ).then((data) => {
      if (data.data) {
        const Data = data.data;
        if (Data.message) {
          console.log("Error: " + Data.message);
          return new TError(0, Data.message)
        }
        if (Data.result && Data.result.result == false && Data.result.message) {
          console.log("Error: " + Data.result.remark);
          return new TError(0, Data.result.remark)
        }
        const decodedToken = jwt_decode(Data.token)
        let tUserData = parseFrom(Data, Data.token, decodedToken);
        return tUserData
      }
      return new TError(0, 'Bir hata oluştu');
    });

}

export async function loginWlinkedinLink(callback: () => void) {
  const redirectTo = window.location.origin + loginRedirectUrl;
  localStorage.setItem("login", "linkedin");
  let headers = {Authorization: `Bearer ${publicToken}`};

  return await axios
    .get(baseUrlV2 + "/Login/loginlinkedingetlink", {
      headers: headers,
      params: {redirectUrl: redirectTo},
    })
    .then((data) => {
      window.location.href = (data.data /*.split(" ")[0]*/);
    });
}

export async function loginWlinkedinLinkSuccess(
  code: string,
) {
  const redirectTo = window.location.origin + dashboardRedirectUrl;

  return axios
    .post(
      baseUrlV2 + "/token/new",
      {
        LoginType: "Linkedin",
        LinkedinParameter: {
          code: code,
          redirectUrl: redirectTo
        },
      }
    ).then((data) => {
      debugger
      if (data.data) {
        const Data = data.data;
        if (Data.message) {
          console.log("Error: " + Data.message);
          return new TError(0, Data.message)
        }
        if (Data.result && Data.result.result == false && Data.result.message) {
          console.log("Error: " + Data.result.remark);
          return new TError(0, Data.result.remark)
        }
        const decodedToken = jwt_decode(Data.token)
        let tUserData = parseFrom(Data, Data.token, decodedToken);
        return tUserData
      }
      return new TError(0, 'Bir hata oluştu');
    });

}


export function parseImageUrl(uri: string) {
  return "https://www.pofft.com/Upload/PagePicture/" + uri;
}
