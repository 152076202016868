import {useContext, useEffect, useRef, useState} from "react";
import {MainContext} from "../context/MainContext";
import {register} from 'swiper/element/bundle';
import {TProject} from "../types/TProject";

interface IProps {
  compact?: boolean
  data: TProject
  customClass?: string
  favoriteProject?: (projectId: string, val: boolean) => Promise<any>
}

export function CSearchResult({compact, data, customClass, favoriteProject}: IProps) {
  const mainContext = useContext(MainContext)
  const [showMore, setShowMore] = useState(false)
  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const swiperElRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false)
  const [isStart, setIsStart] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      register();
      const swiperEl = document.getElementById('swiper' + data.id);
      if (swiperEl) {
        //@ts-ignore
        setSwiper(swiperEl.swiper)
      }
    }, 500)
  }, []);

  useEffect(() => {
    if (swiper != undefined) {
      swiper.on('slideChange', () => {
        setIsEnd(swiper.isEnd)
        setIsStart(swiper.isBeginning)
      })
      swiper.on('paginationUpdate', () => {
        if (swiper.isEnd) {
          setIsEnd(true);
        }
      })
    }
  }, [swiper]);

  function favorite() {
    favoriteProject?.(data.projectKeyID, !data.liked)
  }

  return (
    <div onClick={() => {
      if (data.source != 'pofft' && data.url.length > 0) {
        window.open(data.url, '_blank');
      } else {
        window.location.href = '/project/' + data.projectKeyID
      }
    }} className={"searchResult baseContainer " + customClass}>
      <div className={'flex justify-between'}>
        <div className={'flex gap-[16px] items-center flex-1 pr-[16px]'}>
          <div className='title'>
            {data.title}
          </div>
          {!compact &&
              <>
                  <div className={'dividerVertical'}/>
                  <div className={'flex gap-[8px]'}>
                      <img src={'/images/Location.svg'}/>
                      <div className={'location'}>{data.location ?? '-'}</div>
                  </div>
                  <div className={'dividerVertical'}/>
                  <div className={'description'}>{data.postedAgo}</div>
              </>
          }
        </div>
        {data.source == 'pofft' &&
            <div className='like' onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              favorite();
            }}>
                <img src={data.liked ? '/images/Liked.svg' : '/images/Like.svg'}/>
            </div>
        }
      </div>
      <div className={'pt-[30px] pb-[16px] flex gap-[16px] items-center'}>
        {compact && <>
            <div className={'flex gap-[8px]'}>
                <img src={'/images/Location.svg'}/>
                <div className={'location'}>{data.location ?? '-'}</div>
            </div>
            <div className={'dividerVertical'}/>
            <div className={'description'}>{data.postedAgo}</div>
        </>}
      </div>
      <div className={'darkerdescription'}>
        {(() => {
          let text = ''
          if (data.workingTypeStr.length > 0) {
            text += data.workingTypeStr
          }
          if (data.workingPriceTypeStr.length > 0) {
            if (text.length > 0) {
              text += ' - '
            }
            text += data.workingPriceTypeStr
          }
          if (data.priceRange.length > 0) {
            if (text.length > 0) {
              text += ' - '
            }
            text += 'Min: ' + data.priceRange[0] + ' ' + data.currencySymbol
            if (data.priceRange.length > 1) {
              if (text.length > 0) {
                text += ' - '
              }
              text += 'Max: ' + data.priceRange[1] + ' ' + data.currencySymbol
            }
          }
          return text
        })()}
      </div>
      <div className={'pt-[16px] thindescription break-words'}>
        <div className={showMore ? '' : 'clampedInnerHTML'} dangerouslySetInnerHTML={{__html: data.description}}></div>
        {/*{(data.description.length < 155 || showMore) ? data.description : data.description.substring(0, 151) + '...'}*/}
        {data.description.length >= 155 &&
            <span role={'button'} onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowMore(!showMore)
            }
            } className={'more'}>
              &nbsp;
              {
                showMore ?
                  mainContext?.getTranslation('common', 'daha-az').toLowerCase()
                  :
                  mainContext?.getTranslation('common', 'daha-fazla').toLowerCase()
              }</span>
        }
      </div>
      {data.tags.length > 0 &&
          <div className={'pt-[24px] flex w-full tagsSwiper'}>
              <div className={'pr-[8px] flex items-center h-[32px]'}>
                  <img role={'button'}
                       onClick={() => {
                         swiper.slidePrev();
                       }}
                       style={isStart ? {display: 'none'} : {transform: 'rotate(180deg)'}}
                       src={'/images/FilterArrowRight.svg'}/></div>
              <swiper-container
                  ref={swiperElRef}
                  id={'swiper' + data.id}
                  pagination={'true'}
                  slides-per-view="auto"
                  space-between="8"
              >
                {data.tags.map(t => <swiper-slide>
                  <div className={'slide'}>{t}</div>
                </swiper-slide>)}
              </swiper-container>
              <div className={'pl-[8px] flex items-center h-[32px]'}>
                  <img role={'button'}
                       onClick={() => {
                         if (!isEnd) {
                           swiper.slideNext();
                         }
                       }}
                       style={isEnd ? {display: 'none'} : {}} src={'/images/FilterArrowRight.svg'}/>
              </div>
          </div>
      }
    </div>
  )
    ;
}
