export function setCookie(doc:any,cname:string, cvalue:string) {
  const expireAfter=(24*60*60*1000)
  const d = new Date();
  d.setTime(d.getTime() + expireAfter);
  let expires = "expires="+ d.toUTCString();
  doc.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.pofft.com;secure;path=/";
}

export function getCookie(doc:any,cname:string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(doc.cookie);
  debugger
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
