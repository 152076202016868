import {useContext, useEffect, useState} from "react";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import {getV2} from "src/utils/request";
import {parseTranslation, Translation} from "../../types/Translation";

import styles from './ProjectDetail.module.scss'
import {Button} from "@mui/material";
import {MainContext} from "../../context/MainContext";
import {TProject, TSearchResponse} from "../../types/TProject";
import {GetProjectDetail, updateOffer} from "../../service/projectRequest";
import {BModal} from "../../components/Modal/BModal";
import {NumericFormat} from "react-number-format";
import {COfferUpdatedModal} from "../../components/COfferUpdatedModal";
import {TError} from "../../types/TError";
import {useNavigate} from "react-router-dom";
import {
  currencies,
  getCountries, getCurrencies,
  getHighschools,
  getLanguages,
  getProfessions,
  getSkills, getTranslations,
  getUniversities,
  getWorkingPriceTypes,
  getWorkingTypes
} from "../../service/commonRequest";
import {getDiffToToday} from "../../utils/date";
import {NotificationType} from "../../components/Toast/Toast";
import {useParams} from "react-router";
import {UserType} from "../../types/TUserData";
import {PageState} from "../../types/Context";

export function ProjectDetail() {
  const navigate = useNavigate();
  const {id} = useParams();
  const [project, setProject] = useState<undefined | TProject>(undefined);
  const [similarJobs, setSimilarJobs] = useState<TSearchResponse | undefined>(undefined)
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  const mainContext = useContext(MainContext);

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  async function getData(projectKey: string) {
    getTranslations('proje-sayfa', (translations, data) => {
      setTranslations(translations);
    })
    await getCountries(mainContext);
    await getWorkingPriceTypes(mainContext)
    await getProfessions(mainContext)
    await getLanguages(mainContext)
    await getWorkingTypes(mainContext)
    await getSkills(mainContext)
    await getCurrencies(mainContext)
    await getUniversities(mainContext)
    await getHighschools(mainContext)

    GetProjectDetail(projectKey).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getProject')
        return
      }
      setProject(r)
    });

  }

  useEffect(() => {
    if (localStorage.getItem('userData') == null) {
      navigate('/')
      return
    }
    if (mainContext?.isExpired('project')) {
      setTimeout(() => {
        mainContext.setSigninupState(PageState.Signin)
      }, 1000)
      return;
    }
    if (mainContext?.userData?.token) {
      if (id == undefined || id.length == 0) {
        navigate('/')
        return
      }
      if (mainContext?.publicToken) {
        getData(id)
      }
    }
  }, [mainContext?.userData?.token, mainContext?.publicToken]);

  function getProjectDetail(title: string | undefined, text: string) {
    return <div className={'flex flex-col gap-[9px] items-left text-start'}>
      <p className={styles.detailTitle}>{title}</p>
      <p className={styles.detailText}>{text}</p>
    </div>
  }

  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const [canGoPrev, setCanGoPrev] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(1, 0);
    }
  }, [swiper]);

  const [offerOpen, setOfferOpen] = useState(false)
  const [afterOfferOpen, setAfterOfferOpen] = useState(false)
  const [price, setPrice] = useState<undefined | number>(undefined)
  const [offerText, setOfferText] = useState('')


  useEffect(() => {
    if (price != undefined && project?.priceRange && project.priceRange.length > 1) {
      const minPrice = parseFloat(project!.priceRange[0])
      const maxPrice = parseFloat(project!.priceRange[1])
      if (price < minPrice) {
        setPriceError(getTranslation('sayfa', 'min-ucretten-kucuk-girilemez'))
        return
      } else if (price > maxPrice) {
        setPriceError(getTranslation('sayfa', 'max-ucretten-buyuk-girilemez'))
        return
      }
    }
    setPriceError(undefined)
  }, [price]);

  function onCloseOffer() {
    setOfferOpen(false)
  }


  function onOfferGiven() {
    if (mainContext?.isExpired('offer')) {
      setTimeout(() => {
        mainContext.setSigninupState(PageState.Signin)
      }, 1500)
      return
    }
    updateOffer(project?.projectKeyID, project?.currency?.id, price, offerText, mainContext?.userData?.userType == UserType.Professional).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateOffer')
        return
      }
      onCloseOffer()
      setAfterOfferOpen(true)
    })
  }

  function getDaysBefore(p: TProject) {
    if (p.createDateObj) {
      const now = new Date()
      const diff = now.getTime() - p.createDateObj.getTime()
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      return mainContext?.getTranslation('common', 'gun-once-olusturuldu').replace('{val}', days.toString())
    } else {
      return ''
    }
  }

  const [priceError, setPriceError] = useState<undefined | string>(undefined)

  return project == undefined ? (
    <div className={'h-[100vh] flex-col justify-center'}>
      <CLoadingAnimation/>
    </div>
  ) : (
    <>
      <div
        className="flex justify-between main-width m-auto main-padding pt-[96px] lg:pt-[136px] pb-[72px] relative items-center h-full flex-col gap-[24px]">
        <div className="flex-col gap-[32px] w-full flex">
          <div role={'button'} className={'roundLabel bigFont selected w-fit'}>
            {getTranslation('sayfa', 'proje-detay')}</div>
          <div className={'flex gap-[24px] w-full mt-[16px]'}>
            <div className={'whiteShadowCard pt-[58px] pb-[24px] flex flex-col gap-[24px] flex-1'}>
              <div className={'px-[58px] flex items-start justify-between'}>
                <div className={'flex flex-col gap-[24px]'}>
                  <div className={styles.title}>{project.title}</div>
                  <div className={'searchResult flex gap-[16px]'}>
                    {(project.country != undefined) &&
                        <>
                            <div className={'flex gap-[8px]'}>
                                <img src={'/images/Location.svg'}/>
                                <div className={'location'}>
                                  {(project.country?.label + (project.city ? `, ${project.city.label}` : ''))}
                                </div>
                            </div>
                            <div className={'dividerVertical !h-auto'}/>
                        </>
                    }
                    {project.createDateObj &&
                        <div
                            className={'description self-center'}>{getDaysBefore(project)}</div>
                    }
                  </div>
                </div>
                {/*berk undo*/}
                {/*{(isOfferableJobStatus(project.offerStatus) || !project.hasConfirmOffer) ?*/}
                <Button
                  className="purpleButtonWide pb-[32px]"
                  onClick={() => {
                    setOfferOpen(true)
                  }}
                >{getTranslation('sayfa', 'teklif-ver')}</Button>
                {/*:*/}
                {/*  <div*/}
                {/*    className={'text-purple text-16-24-500'}>{getTranslation('sayfa', `projectStatus3_${project.offerStatus}`)}</div>*/}
                {/*}*/}
              </div>
              <div className={'dividerHorizontal'}></div>
              <div className={`px-[58px] ${styles.description}`}
                   dangerouslySetInnerHTML={{__html: project.description}}/>
            </div>
            <div className={'whiteShadowCard min-w-[240px] p-[24px] flex flex-col items-start text-left gap-[16px]'}>
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-paymentType'), project.workingPriceType?.label ?? '')}
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-paymentRange'), project.priceRange[0] + (project.currency?.code ?? '') + " - " + project.priceRange[1] + (project.currency?.code ?? ''))}
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-deliveryTime'), project.endDateObj == undefined ? '-' :
                `${mainContext?.getTranslation('common', 'gun-val').replace('{val}', ('' + getDiffToToday(project.endDateObj)))}`)}
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-expertise'), project.professionsMap?.map(p => p.label).join(', ') ?? '-')}
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-language'), project.languagesMap?.map(l => l.label).join(', ') ?? '-')}
              {getProjectDetail(mainContext?.getTranslation('filters', 'job-workType'), project.workingTypesMap?.map(w => w.label).join(', ') ?? '-')}
            </div>
          </div>
          <div className={'whiteShadowCard py-[36px] px-[24px] flex flex-col gap-[24px]'}>
            <div className={styles.skills}>{getTranslation('sayfa', 'yetenekler')}</div>
            <div className={'flex gap-[16px] w-full flex-wrap'}>
              {project.skillsMap?.map(t =>
                <div role={'button'} className={'roundLabel w-fit'}>{t.label}</div>
              )}
            </div>
          </div>
        </div>

      </div>


      <BModal isOpen={offerOpen} saveDisabled={priceError != undefined || price == undefined} onClose={onCloseOffer}
              title={getTranslation('sayfa', 'teklif-ver')}
              onSave={onOfferGiven} children={<div className={'flex flex-col pt-[20px]'}>
        <div className={'description'}>{getTranslation('sayfa', 'teklif-tutar-title')}</div>
        <div className={'h-[16px]'}></div>
        <div className={'header'}>{getTranslation('sayfa', 'tutari-sirket-gorecek')}</div>

        <div className={'flex flex-col gap-[8px] pt-[12px] w-full max-w-[200px]'}>
          <NumericFormat
            className={'standardInput'}
            thousandSeparator={','}
            placeholder={'0.00 TL'}
            // customInput={TextField}
            onValueChange={(values) => setPrice(values.floatValue)}
            value={price}
            suffix={` ${currencies.find(c => c.code == 'TL')?.code ?? ''}`}
          />
          {priceError && (
            <div className="text-red-400 text-12-14-500 pt-[8px]">
              {priceError}
            </div>
          )}
        </div>
        <div className={'pt-[32px]'}>
          <div className={'header'}>{getTranslation('sayfa', 'sirkete-kendinden-bahset')}</div>
        </div>
        <div className={'pt-[12px]'}>
          <textarea placeholder={getTranslation('sayfa', 'neden-basvurdugunu-anlat')} rows={5}
                    className={'standardInput py-[12px] !h-[180px] !color-[#6D6D6D]'}
                    onChange={(e) => {
                      setOfferText(e.target.value)
                    }}
          />
        </div>
      </div>}
      />
      <COfferUpdatedModal isOpen={afterOfferOpen} close={() => setAfterOfferOpen(false)}
                          getTranslation={getTranslation}
                          showUpdateButton={false} updateText={`${getTranslation('sayfa', 'teklifiniz-iletildi')}.`}/>
    </>
  );
}
